import { ref, reactive } from 'vue';
import Target from '@/api/Target';
import { over } from 'lodash';
export default () => {
  const overallProgress = reactive({});
  const rows = reactive([]);
  const loading = ref(false);

  const fetcher = () => {
    loading.value = true;
    Target.result()
      .then((res) => {
        if (res.code === 1) {
          rows.push(...res.data.rows);
          Object.assign(overallProgress, res.data.overallProgress);
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return { fetcher, overallProgress, rows };
};
