<template>
  <div class="">
    <Intro v-if="!targets.length" class="py-6 mt-6 md:mt-0 md:pt-0"></Intro>

    <!-- 我的目標 -->
    <div
      v-if="targets.length || targetHistory.length"
      class="grid grid-cols-4 gap-6 mb-8 md:grid-cols-1"
    >
      <template v-if="targets.length">
        <Status class="col-span-3 md:col-span-1"></Status>
        <Tasks></Tasks>
      </template>

      <Target-History
        v-if="targetHistory.length"
        class="col-span-4 md:col-span-1"
      />
    </div>

    <Transition name="fade">
      <div
        v-if="!analyzeDataLoading && !barLoading"
        class="col-span-2 md:col-span-1"
      >
        <div class="flex justify-between">
          <Title text="科目表現分析" />
          <el-button
            type="text"
            size="default"
            @click="$router.push({ path: '/analyze' })"
            >看更多分析</el-button
          >
        </div>
        <div
          class="bg-white rounded shadow p-4 grid gap-4 grid-cols-2 mt-4 md:grid-cols-1 md:gap-8"
        >
          <!-- 科目表現分析 -->
          <Table
            :loading="barLoading"
            class="border p-2 rounded"
            title="科目"
            :data="analyzeData.subject"
            @update:order="onSort"
            @update:datatype="onWatchAnalyze"
          />

          <!-- 各科答對率 -->
          <RateBar :data="analyzeData.subject" />

          <!-- 整體表現 -->

          <OverallBar
            v-if="bar.data"
            :data="bar.data"
            class="col-span-2 md:col-span-1"
          />

          <Overview
            v-if="analyzeData.overall"
            :data="analyzeData.overall"
            class="col-span-2 md:col-span-1"
          />
        </div>
      </div>
      <div v-else class="py-12">載入中...</div>
    </Transition>
    <div class="py-6 border-t">
      <div class="flex justify-between">
        <div>
          <font-awesome-icon :icon="['fas', 'star']" class="text-yellow" />
          為您推薦的最新試卷
        </div>
        <el-button
          type="text"
          size="default"
          class="underline"
          @click="$router.push({ path: '/exam/past/list' })"
          >查看更多最新試卷</el-button
        >
      </div>
      <List :rows="rows" :addable="false"></List>
    </div>
    <div class="py-6 border-t">
      <div class="flex justify-between">
        <div>
          <font-awesome-icon :icon="['fas', 'star']" class="text-yellow" />
          最新消息
        </div>
        <el-button
          type="text"
          size="default"
          class="underline"
          @click="$router.push({ path: '/news' })"
          >查看更多最新消息</el-button
        >
      </div>
      <News class="mt-4"></News>
    </div>
  </div>
</template>

<script>
import News from '@/modules/news/Slider.vue';
import Intro from '@/modules/target/Intro.vue';
import Status from '@/modules/target/status/Index.vue';
import Tasks from '@/modules/target/tasks/Index.vue';
import List from '@/modules/exam/list/Grid.vue';

import useExam from '@/modules/exam/useExam';
import Exam from '@/api/Exam.js';
import { provide, inject, onMounted, ref } from 'vue';
import useTargetStatus from '@/modules/target/status/useTargetStatus';

import Title from '@/components/Titles.vue';
import Table from '@/modules/analyze/chats/Table.vue';
import RateBar from '@/modules/analyze/chats/RateBar.vue';
import Overview from '@/modules/analyze/widget/Overview.vue';
import OverallBar from '@/modules/analyze/chats/OverallBar.vue';
import useAnalyze from '@/modules/analyze/useAnalyze.js';
import useBar from '@/modules/analyze/useBar.js';
import dayjs from 'dayjs';
import TargetHistory from '@/modules/target/history/Index.vue';
import useTargetHistory from '@/modules/target/history/useHistory.js';

import { dateRange2String } from '@/utils/index.js';
import { useRouter } from 'vue-router';
export default {
  components: {
    List,
    News,
    Intro,
    Status,
    Tasks,
    TargetHistory,
    Title,
    Table,
    RateBar,
    Overview,
    OverallBar,
  },
  setup() {
    const targetStatus = useTargetStatus();
    provide('targetStatus', targetStatus);

    const targetHistory = useTargetHistory();
    provide('targetHistory', targetHistory);

    provide('folder', null);

    const exams = useExam({
      method: ref(Exam.recommend),
      defaultForm: { type: 'mine' },
    });
    provide('exams', { ...exams });

    const { saveds, updateSaved } = exams;
    provide('actions', { saveds, updateSaved });
    const period = {
      start: dayjs().subtract(182, 'day').format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
    };
    const params = {
      period: dateRange2String(period.start, period.end),
    };

    const analyzeData = useAnalyze({
      params,
    });
    provide('analyzeData', analyzeData);

    const onSort = (params) => {
      analyzeData.sort({ listKey: 'subject', ...params });
    };
    const router = useRouter();
    const onWatchAnalyze = (id) => {
      router.push({
        name: 'Analyze',
        query: {
          ...period,
          dataType: id,
        },
      });
    };

    const bar = useBar({
      params,
    });

    provide('overallBarData', bar);

    onMounted(() => {
      targetStatus.fetcher();
      targetHistory.fetcher();
      analyzeData.fetcher();
      bar.fetcher();
      exams.fetcher();
    });
    return {
      targets: targetStatus.rows,
      targetHistory: targetHistory.rows,
      bar,
      barLoading: bar.loading,
      analyzeData,
      analyzeDataLoading: analyzeData.loading,
      rows: exams.rowsData.rows,
      onWatchAnalyze,
      onSort,
    };
  },
};
</script>
