<template>
  <div class="flex justify-center">
    <el-progress
      type="circle"
      :percentage="rate"
      :stroke-width="10"
      :color="getProgressColors()"
      :width="200"
    >
      <template #default="{ percentage }">
        <div :style="{ color: getScoreColor(percentage) }">
          <span class="text-5xl font-bold">{{ percentage }}</span>
          <span class="text-base -mt-2">%</span>
        </div>
        <div class="text-sm mt-1">
          <span class="text-gray">{{ ratio }} </span>
        </div>
      </template>
    </el-progress>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { ElProgress } from 'element-plus';
import { getProgressColors, getScoreColor } from '@/utils/index.js';

export default defineComponent({
  components: {
    ElProgress,
  },
  props: {
    rate: {
      type: Number,
      default: 0,
    },
    ratio: {
      type: String,
      default: '',
    },
  },

  setup: () => {
    return { getProgressColors, getScoreColor };
  },
});
</script>
