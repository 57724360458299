<template>
  <div class="flex space-x-8 py-4 md:flex-wrap md:space-x-0">
    <div class="flex-grow md:w-3/5">
      <div class="text-xs text-primary">
        {{ row.timeStart }} - {{ row.timeEnd }}
      </div>
      <div class="mt-1 text-lg">{{ row.sbjName }}</div>
    </div>
    <div class="md:w-2/5">
      <div class="text-xs text-gray">目標(每日/總題數)</div>
      <div class="mt-1 text-lg">{{ row.dailyRatio }}</div>
    </div>
    <div class="md:w-3/5 md:mt-2">
      <div class="text-xs text-gray">總進度</div>
      <div class="mt-2">
        <ElProgress
          type="line"
          :percentage="row.progressRate"
          :stroke-width="10"
          :color="customColors"
        ></ElProgress>
      </div>
    </div>
    <div class="md:w-2/5 md:mt-2">
      <div class="text-xs text-gray">正確率 / 目標正確率</div>
      <div class="mt-1 text-lg">
        {{ row.correctRate }}%
        <span class="text-info-4 mx-1"> / </span>
        <span class="text-info-4"> {{ row.targetCorrectRate }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ElProgress } from 'element-plus';
export default {
  components: {
    ElProgress,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const customColors = [
      { color: 'var(--el-color-error)', percentage: 40 },
      { color: 'var(--el-color-alert)', percentage: 60 },
      { color: 'var(--el-color-info)', percentage: 80 },
      { color: 'var(--el-color-success)', percentage: 100 },
    ];

    return { customColors };
  },
};
</script>

<style lang="sass" scoped>
.el-progress--line
  width: 150px
  @screen md
    width: 100%
</style>
