<template>
  <div class="p-8 rounded shadow bg-white">
    <div class="text-2xl mb-4">目標紀錄</div>

    <ElTable :data="rows" height="280">
      <el-table-column label="考試科目" prop="sbjName"></el-table-column>
      <el-table-column label="目標期間">
        <template #default="scope">
          {{ scope.row.timeStart }} -{{ scope.row.timeEnd }}
        </template>
      </el-table-column>

      <el-table-column
        label="實答/目標(總題數)"
        prop="dailyRatio"
        align="right"
      ></el-table-column>
      <el-table-column
        label="正確率 / 目標正確率"
        prop="correctRate"
        align="right"
      >
        <template #default="scope">
          <div
            v-text="
              (scope.row.correctRate < 0 ? '-' : scope.row.correctRate) +
              '/' +
              scope.row.targetCorrectRate
            "
          ></div>
        </template>
      </el-table-column>
      <el-table-column label="是否達標" prop="achieved" align="center">
        <template #default="scope">
          <div v-if="scope.row.achieved == 1">
            <font-awesome-icon :icon="['fas', 'check']" class="text-info-2" />
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
    </ElTable>
  </div>
</template>
<script setup>
import { inject } from 'vue';
import { ElTable, ElTableColumn } from 'element-plus';

const { rows } = inject('targetHistory');
</script>
