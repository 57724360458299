<template>
  <div class="p-8 bg-white shadow rounded">
    <div class="flex justify-between border-b pb-4">
      <div class="text-2xl">個人目標</div>
      <el-button type="text" @click="$router.push({ name: 'Assistant.target' })"
        >編輯</el-button
      >
    </div>
    <div
      v-loading="loading"
      class="flex pt-4 space-x-8 md:flex-col md:items-center md:space-x-0"
    >
      <div class="w-[250px] py-4 md:w-auto">
        <div class="text-center text-gray">每日達成總題數</div>
        <Chart
          class="mt-8"
          :rate="overallProgress.rate"
          :ratio="overallProgress.ratio"
        ></Chart>
      </div>

      <div class="flex-grow divide-y">
        <Subject v-for="row in rows" :key="row.id" :row="row"></Subject>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from './chart.vue';
import Subject from './subject.vue';
import { inject, defineComponent } from 'vue';

export default defineComponent({
  components: {
    Chart,
    Subject,
  },

  setup() {
    const { overallProgress, rows, loading } = inject('targetStatus');

    return { overallProgress, rows, loading };
  },
});
</script>
