<template>
  <div class="bg-white shadow rounded p-4 flex flex-col">
    <div class="flex space-x-4 justify-between items-center">
      <El-Button class="px-1" @click="prev">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="text-gray" />
      </El-Button>
      <ElDatePicker
        v-model="date"
        type="date"
        class="w-full"
        format="YYYY / MM / DD   ddd"
        value-format="YYYY-MM-DD"
        :clearable="false"
      >
        <template #default="cell">
          <div class="cell" :class="{ current: cell.isCurrent }">
            <span class="text">{{ cell.text }}</span>
            <span v-if="isTaskDay(cell)" class="taskDay" />
          </div>
        </template>
      </ElDatePicker>
      <El-Button class="px-1" @click="next">
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-gray" />
      </El-Button>
    </div>

    <div
      v-loading="loading"
      class="bg-primary-light rounded py-4 flex divide-x mt-4"
    >
      <div class="flex justify-center items-end px-2 w-1/2">
        <div>當日總題數</div>
        <div class="text-primary text-xl ml-1">{{ status.qTotal || 0 }}</div>
      </div>
      <div class="flex justify-center items-end px-2 w-1/2">
        <div>已達成</div>
        <div class="text-yellow text-xl ml-1">
          {{ status.completedCnt || 0 }}
        </div>
      </div>
    </div>

    <div
      v-loading="loading"
      class="mt-4 border bg-primary-light p-4 text-sm text-gray flex-grow rounded"
    >
      <div class="flex flex-wrap leading-loose">
        <div v-for="row in rows" :key="row.sbjName" class="mr-2">
          ({{ row.qCnt }}) {{ row.sbjName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElDatePicker } from 'element-plus';
import { ref, reactive, watch } from 'vue';
import dayjs from 'dayjs';
import Target from '@/api/Target.js';
export default {
  components: {
    ElDatePicker,
  },
  setup() {
    const date = ref(dayjs().format('YYYY-MM-DD'));
    const loading = ref(false);
    const taskDay = [
      '2022-03-11',
      '2022-03-12',
      '2022-03-13',
      '2022-03-14',
      '2022-03-15',
      '2022-03-16',
      '2022-03-17',
      '2022-03-22',
      '2022-03-23',
    ];
    const status = reactive({
      completedCnt: 0,
      qTotal: 0,
    });
    const rows = reactive([]);

    const fetcher = () => {
      loading.value = true;
      Target.daily({ date: date.value })
        .then((res) => {
          if (res.code === 1) {
            rows.length = 0;
            if (res.data.rows) {
              rows.push(...res.data.rows);
            }
            status.completedCnt = res.data.completedCnt;
            status.qTotal = res.data.qTotal;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const next = () => {
      date.value = dayjs(date.value).add(1, 'day').format('YYYY-MM-DD');
    };

    const prev = () => {
      date.value = dayjs(date.value).subtract(1, 'day').format('YYYY-MM-DD');
    };

    watch(
      date,
      () => {
        fetcher();
      },
      {
        immediate: true,
      },
    );

    const isTaskDay = ({ dayjs }) => {
      return taskDay.includes(dayjs.format('YYYY-MM-DD'));
    };
    return {
      date,
      taskDay,
      isTaskDay,
      fetcher,
      status,
      rows,
      loading,
      next,
      prev,
    };
  },
};
</script>

<style lang="scss" scoped>
.cell {
  height: 30px;
  padding: 3px 0;
  box-sizing: border-box;
}
.cell .text {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.cell.current .text {
  background: var(--el-color-success);
  color: #fff;
}
.cell .taskDay {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--el-color-alert);
  border-radius: 50%;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
