import { reactive, ref } from 'vue';
import Target from '@/api/Target.js';
import { ElTable, ElTableColumn } from 'element-plus';

export default () => {
  const rows = reactive([]);

  const fetcher = () => {
    Target.history().then((res) => {
      if (res.code === 1) {
        rows.push(...res.data.rows);
      }
    });
  };

  return { rows, fetcher };
};
