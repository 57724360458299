<template>
  <div class="flex md:flex-col-reverse">
    <div
      class="w-1/2 md:w-full py-8 flex items-center px-8 md:px-0 justify-center"
    >
      <div class="text-center">
        <div class="text-5xl">歡迎加入 JODY</div>
        <div class="mt-4 leading-loose text-2xl">
          現在就設定個人目標，隨時 on track
        </div>
        <ElButton
          type="primary"
          size="large"
          round
          class="mt-8 text-lg"
          @click="$router.push({ name: 'Assistant.target' })"
          >開始設定</ElButton
        >
      </div>
    </div>
    <div
      class="w-1/2 md:w-full flex justify-start items-start md:justify-center"
    >
      <img src="@/assets/imgs/index-role2.png" alt class="max-w-md md:w-2/3" />
    </div>
  </div>
</template>

<script>
// import { inject } from 'vue';
export default {
  setup() {
    // const { rows } = inject('targetStatus');
    // return { rows };
  },
};
</script>

<style lang="scss" scoped></style>
